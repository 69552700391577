import { BrandVariants, Theme, createDarkTheme, createLightTheme } from "@fluentui/react-components";

const cognitus: BrandVariants = {
    10: "#070200",
    20: "#281004",
    30: "#441608",
    40: "#5B190A",
    50: "#741B0B",
    60: "#8D1B0B",
    70: "#A71B09",
    80: "#C21807",
    90: "#DD1204",
    100: "#F90400",
    110: "#FF4425",
    120: "#FF6946",
    130: "#FF8665",
    140: "#FF9E83",
    150: "#FFB59F",
    160: "#FFCBBB"
};

const lightTheme: Theme = {
    ...createLightTheme(cognitus),
};

const darkTheme: Theme = {
    ...createDarkTheme(cognitus),
    colorBrandForeground1: cognitus[120]
};


darkTheme.colorBrandForeground1 = cognitus[110];
darkTheme.colorBrandForeground2 = cognitus[120];

export {
    lightTheme,
    darkTheme
}