import { ComboboxProps, Combobox, Option, useId, Button, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { useRef, useState, useEffect } from "react";
import { Person } from "../interfaces";


const useStyles = makeStyles({
    root: {
        // Stack the label above the field with a gap
        display: "grid",
        gridTemplateRows: "repeat(1fr)",
        justifyItems: "start",
        ...shorthands.gap("2px"),
        maxWidth: "400px",
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        gridGap: tokens.spacingHorizontalXXS,
    },
});

const EmailCombobox = (props: any) => {


    // generate ids for handling labelling
    const comboId = useId("combo-multi");
    const selectedListId = `${comboId}-selection`;
    
    const comboboxInputRef = useRef<HTMLInputElement>(null);
    const selectedListRef = useRef<HTMLUListElement>(null);

    const [selectedOptions, setSelectedOptions] = useState<Array<Person>>([]);
    const [value, setValue] = useState<Person | ReadonlyArray<Person> & Person>([] as any);
    const [matchingOptions, setMatchingOptions] = useState<Array<Person>>([]);
    const [emails, setEmails] = useState<Array<Person>>([])

    const styles = useStyles();

    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim().toLowerCase();
        setValue(JSON.parse(value));
        if (value === undefined || value === null || value === '') {
            setMatchingOptions(emails);
            return;
        }
        const matches =emails.filter(
            (person: { name: string, email: string }) => {
                let split = (person.name + ' <'+person.email+'>').toLowerCase().split(' <');
                let match = false;
                if (value.startsWith(split[0]) || split[0].startsWith(value)) {
                    match = true;
                }
                if (value.startsWith(split[1].split(">")[0]) || split[1].split(">")[0].startsWith(value)) {
                    match = true;
                }
                return match;
            }
        );
        setMatchingOptions(matches);
    };

    useEffect(() => {
        if (props.emails.length > 0) {
            let emails = props.emails.map((oneLiner: string) => {
                let split = oneLiner.split(" <")
                return {
                    name: split[0],
                    email: split[1].split(">")[0]
                }
            })
            setEmails(emails)
            setMatchingOptions(emails)
        }
    }, [props.emails])

    const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        // console.log(data);
        setSelectedOptions(data.selectedOptions.map(x => JSON.parse(x)));
        props.setItemData(data.selectedOptions.map(x => JSON.parse(x)))
    };

    useEffect(() => {
        // console.log(props.selectedOptions)
        if (props.selectedOptions !== undefined)
            setSelectedOptions(props.selectedOptions);
    }, [props.selectedOptions])

    useEffect(() => {
        // console.log(value);
        if (props.value !== undefined) {
            setValue(props.value)
        }
    }, [props.value])

    const onTagClick = (option: Person, index: number) => {
        // remove selected option
        let newSelectedOptions = selectedOptions.filter((o) => o !== option)
        setSelectedOptions(newSelectedOptions);
        props.setItemData(newSelectedOptions)

        // focus previous or next option, defaulting to focusing back to the combo input
        const indexToFocus = index === 0 ? 1 : index - 1;
        const optionToFocus = selectedListRef.current?.querySelector(
            `#${comboId}-remove-${indexToFocus}`
        );
        if (optionToFocus) {
            (optionToFocus as HTMLButtonElement).focus();
        } else {
            comboboxInputRef.current?.focus();
        }
    };

    const labelledBy =
        selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

    return (
        <div style={{width: '100%'}}>
            {props.useTags && selectedOptions.length ? (
                <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', textAlign: 'center', gap: '2em' }}>
                    <ul
                        id={selectedListId}
                        className={styles.tagsList}
                        ref={selectedListRef}
                    >
                        {/* The "Remove" span is used for naming the buttons without affecting the Combobox name */}
                        <span id={`${comboId}-remove`} hidden>
                            Remove
                        </span>
                        {selectedOptions.map((option, i) => (
                            <li key={option.email + '-' + i}>
                                <Button
                                    size="small"
                                    shape="circular"
                                    appearance="primary"
                                    icon={<Dismiss12Regular />}
                                    iconPosition="after"
                                    onClick={() => onTagClick(option, i)}
                                    id={`${comboId}-remove-${i}`}
                                    aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
                                >
                                    {option.name + ' <' + option.email + '>'}
                                </Button>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : null }
            <Combobox
                style={{ width: '100%' }}
                multiselect={props.multiple}
                placeholder={props.placeholder}
                selectedOptions={selectedOptions.map(option => option.name + ' <' + option.email + '>')}
                defaultValue={(value as any).map((option: any) => option.name ) as any}
                value={(value as any).map((option: any) => option.name ) as any}
                onOptionSelect={onSelect}
                ref={comboboxInputRef}
                aria-labelledby={labelledBy}
                onChange={onChange}
                appearance={props.appearance}
            >
                {matchingOptions.map((person: { email: string, name: string }, index: number) =>
                    <Option key={person.name + ' <' + person.email + '>'} value={JSON.stringify(person)}>{person.name + ' <' + person.email + '>'}</Option>
                )}
                {matchingOptions.length === 0 ? (
                    <Option key="no-results" text="">No Results Found</Option>
                ) : null}
            </Combobox>
        </div>
        
    )
}

export default EmailCombobox