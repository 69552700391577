// https://fluentsite.z22.web.core.windows.net/quick-start
// import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { FluentProvider, Spinner, teamsHighContrastTheme, Theme } from '@fluentui/react-components';
import { darkTheme, lightTheme } from './cognitusTheme'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import RequestTab from "./RequestTab";
import "./App.css";
import { TeamsFxContext } from "./Context";
import { Navigate } from 'react-router-dom';
import { ThemePrepared } from '@fluentui/react-northstar';
// import ManageTab from './ManageTab';
// import { Providers, ProviderState } from '@microsoft/mgt-element';
// import { TeamsFxProvider } from '@microsoft/mgt-teamsfx-provider';
// import { Client } from '@microsoft/microsoft-graph-client';



const themeSelector = (themeString: string): Partial<Theme> => {
  switch (themeString) {
    case 'dark':
      return darkTheme
    case 'contrast':
      return teamsHighContrastTheme
    default:
      return lightTheme
  }
}


/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {


  let { loading, theme, themeString, teamsUserCredential, inTeams } = useTeamsUserCredential(
      {
        "initiateLoginEndpoint": "https://sysaccess.cognitusconsulting.com/auth-start.html",
        // "initiateLoginEndpoint": "https://sysaccess-dev-frontend.cognitus.com/auth-start.html",
        // "applicationIdUri": "https://sysaccess.cognitusconsulting.com/auth-start.html",
        "clientId": '1fab7b3e-9071-4031-a28b-b63c2c7696d8',
      }
    )


  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }} >
      <FluentProvider theme={themeSelector(themeString)} style={{ width: '100%', minWidth: 'max-content'}}>
        {
          (loading) ? (
            <Spinner style={{ margin: 100 }} />
          ) : !inTeams ? (
            (
              <div className="wbsassign">
                <h1>This application only works when used inside the Teams App.</h1>
              </div>
            )
          ) : (
            <div className="app" >
              <Router>
                <Routes>
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} />
                  <Route path="/tab" element={<RequestTab/>} />
                  <Route path="/manage" element={<RequestTab/>} />
                  <Route path="/" element={<Navigate to="/tab" replace />} />
                </Routes>
              </Router>
            </div>
          )
        }
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
