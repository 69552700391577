export type AlertDictionary = {
    [key: string]: AlertType | undefined
}

export type AlertType = {
    id: string
    message: string
    intent: AlertIntent
    timeout?: number
};

export type AlertIntent = 'info' | 'success' | 'error' | 'warning';

export type Person = {
    name: string;
    email: string;
}

export type RoleInfoAccess = 'BASIS' | 'TECHNICAL' | 'FUNCTIONAL' | 'SECURITY' | any
export type RoleInfoTechRol = "S_A.SYSTEM" | "YS4C:BASIS" | "YS4C:FUNCTIONAL" | "YS4C:SECURITY" | "S_A.DEVELOP" | "YS4C:TECHNICAL" | any
export type RoleInfoRoleType = "PRF" | "ROL"
export interface RoleInfo {
    "ACCESS": RoleInfoAccess,
    "TECH_ROLE1": RoleInfoTechRol,
    "ROLE_TYPE": RoleInfoRoleType
}

export interface ApproverDataItem {
    "Name": string//"Formosa Java",
    "Version": string//"ADS 7.5",
    "SID": string//"FMJ",
    "Type": string//"JAVA",
    "ASCS Server": string//"fmj.cognitusconsulting.com",
    "Message Server": string//'',
    "Logon Group": string//,
    "Port": string//"",
    "Instance Number": string//"01",
    "Client": string//"000",
    "Client Description": string//"Default Client",
    "Functional Approver Email": string//"--",
    "Functional Approver Name": string//"--",
    "Functional Ref User": string
    "Technical (ABAP) Approver Email": string//"--",
    "Technical (ABAP) Approver Name": string//"--",
    "Technical Ref User": string
    "Basis Approver Email": string//"sbrown@cognitus.com"
    "Basis Approver Name": string//"sbrown@cognitus.com"
    "Basis Ref User": string,
    "Security Approver Email": string
    "Security Approver Name": string
    "Security Ref User": string
    "SAP GUI": string,
    "Requestable": string,
    "Workspace": string,
    "Message Server Port": string
    "GRC Connector": string
    "Training": string
}

export interface UniqueApproverDataItem extends ApproverDataItem {
    UUID: string
    hash: string
}

export const DefaultApproverDataItem = {
    "Name": "Formosa Java",
    "Version": "ADS 7.5",
    "SID": "FMJ",
    "Type": "JAVA",
    "ASCS Server": "fmj.cognitusconsulting.com",
    "Message Server": "",
    "Logon Group": "",
    "Port": "",
    "Instance Number": "",
    "Client": "000",
    "Client Description": "Default Client",
    "Functional Approver Email": "--",
    "Functional Approver Name": "--",
    "Functional Ref User": "ZCC_REF",
    "Technical (ABAP) Approver Email": "--",
    "Technical (ABAP) Approver Name": "--",
    "Technical Ref User": "ZCC_REF",
    "Basis Approver Email": "sbrown@cognitus.com",
    "Basis Approver Name": "sbrown@cognitus.com",
    "Basis Ref User": "ZCC_REF",
    "Security Approver Email": "--",
    "Security Approver Name": "--",
    "Security Ref User": "ZCC_SEC",
    "SAP GUI": "X",
    "Requestable": "X",
    "Workspace": "CIS-AD",
    "Message Server Port": "3600",
    "GRC Connector": "",
    "Training": "X"
} as ApproverDataItem



export enum RequestStatus {
    REQUESTED,
    APPROVED,
    REJECTED,
    MANUAL_ACTION,
    COMPLETED
}

export interface PouchDBMetaID {
    _id: string
}

export interface SystemAccessRequest {
    requester: string,
    approverType: string
    sid: string
    name: string
    version: string
    type: string
    client: {
        id: number,
        description: string
    },
    address: string
    port: number
    approver: string
    refid: string
    status: RequestStatus
    instanceNumber: string
    reason: string
    comment: string
    timestamp: Date
    grcConnector: string
}

export interface GRCSystemAccessRequest {
    requester: string,
    requestedBy: string,
    reason: string,
    servers: Array<Omit<SystemAccessRequest, 'requester' | 'requestedBy'>>,
    timestamp: Date
    status: RequestStatus
    grcRequestId: string
}

export enum ApproverType {
    Functional,
    Technical,
    Basis,
    Security
}

export type ServerCell = {
    sid: string;
    name: string;
    type: string;
    version: string
    client: ClientCell;
    port: number;
    address: string;
    approvers: Map<ApproverType, ApproverCell<ApproverType>>;
};

export type ClientCell = {
    id: number,
    description: string
}

export type ApproverCell<T extends ApproverType> = {
    approverType: T;
    approverName: string;
    approverEmail: string;
    referenceUser: string
};

export type ApproverItem = {
    adiUUID: string;
    hash: string;
    currentHash: string;
    server: ServerCell;
};

export enum DisplayMode {
    Both,
    Edit,
    View
}