import { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";
// import WBSAssignRequest from "./wbsassign/WBSAssignRequest";
// import { LeanStaffingAssignment, WBSAssignItem } from "./wbsassign/types";
import { useData } from "@microsoft/teamsfx-react";
import { Spinner } from "@fluentui/react-components";
import SystemsTable from "./SystemsTable";
import axios from "axios";
import { AlertDictionary, AlertType, ApproverDataItem, GRCSystemAccessRequest, PouchDBMetaID, RoleInfo, SystemAccessRequest } from "../interfaces";
import TimedAlert from './TimedAlert'
import { AccessToken } from "@azure/core-auth";
import { UserInfo } from "@microsoft/teamsfx";

const RequestTab = (props: any) => {

  const [data, setData] = useState<Array<ApproverDataItem>>([]);
  const [roleInfo, setRoleInfo] = useState<Array<RoleInfo>>([]);
  const [requests, setRequests] = useState<Array<any>>([]);
  const [grcRequests, setGRCRequests] = useState<Array<any>>([]);
  const [emails, setEmails] = useState<Array<string>>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [alerts, setAlerts] = useState<AlertDictionary>({})
  const [grcMode, setGrcMode] = useState<'formosa' | 'grc'>('formosa')
  const [requestedFor, setRequestedFor] = useState<string>('');
  const [showHistory, setShowHistory] = useState<boolean>(false);


  const { teamsUserCredential } = useContext(TeamsFxContext);

  const graphData = useData<{ userToken: AccessToken | null, userInfo: UserInfo } | undefined>(async () => {
    if (teamsUserCredential) {
      let userToken
      try {
        userToken = await teamsUserCredential.getToken('User.Read');
      } catch (error) {
        console.error(error);
        await teamsUserCredential.login('User.Read')
        userToken = await teamsUserCredential.getToken('User.Read');
        // return { userInfo: { preferredUserName: '' }, userToken: { token: null } } as any
      }
      
      const userInfo = await teamsUserCredential.getUserInfo();
      setRequestedFor(userInfo.preferredUserName)
      fetchData(userToken);
      fetchRequests(userToken)
      return { userToken , userInfo };
    }
  })

  // const graphData = { loading: false, error: null as unknown as any, profile: { mail: '' }, data: { userInfo: { preferredUserName: '' }, userToken: { token: null }} }

  

  const sendAlert = (alert: AlertType) => {
    
    setAlerts(alerts => {
      alerts[alert.id] = alert
      return alerts;
    });

  }

  const fetchGRCRequests = async (userToken: AccessToken | null) => {
    const requests = (await axios.get(`${process.env.REACT_APP_ENV === 'DEV' ? 'https://sysaccess-dev.cognitus.com' : ''}/requests?grc=${grcMode === 'grc'}&env=${process.env.REACT_APP_ENV === 'DEV' ? 'DEVELOPMENT' : 'PRODUCTION'}`, { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<GRCSystemAccessRequest & PouchDBMetaID>
    const timestamped = requests.filter(request => request.timestamp !== undefined).sort((requestA: GRCSystemAccessRequest & PouchDBMetaID, requestB: GRCSystemAccessRequest & PouchDBMetaID) => (new Date(requestB.timestamp).getTime() - new Date(requestA.timestamp).getTime()) > 0 ? 1 : -1)
    setGRCRequests([...timestamped, ...requests.filter(request => request.timestamp === undefined)])
  } 

  const fetchRequests = async (userToken: AccessToken | null) => {
    const requests = (await axios.get(`${process.env.REACT_APP_ENV === 'DEV' ? 'https://sysaccess-dev.cognitus.com' : ''}/requests?grc=${grcMode === 'grc'}&env=${process.env.REACT_APP_ENV === 'DEV' ? 'DEVELOPMENT' : 'PRODUCTION'}`, { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<SystemAccessRequest & PouchDBMetaID>
    const timestamped = requests.filter(request => request.timestamp !== undefined).sort((requestA: SystemAccessRequest & PouchDBMetaID, requestB: SystemAccessRequest & PouchDBMetaID) => (new Date(requestB.timestamp).getTime() - new Date(requestA.timestamp).getTime()) > 0 ? 1 : -1)
    setRequests([...timestamped, ...requests.filter(request => request.timestamp === undefined)])
  }


  const fetchData = async (userToken: AccessToken | null) => {
    setLoadingData(true);
    setData(((await axios.get(`${process.env.REACT_APP_ENV === 'DEV' ? 'https://sysaccess-dev.cognitus.com' : ''}/systemsList?grc=${grcMode === 'grc'}&env=${process.env.REACT_APP_ENV === 'DEV' ? 'DEVELOPMENT' : 'PRODUCTION'}`, { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<ApproverDataItem>).filter(entry => entry.Requestable === 'X'))
    if (grcMode) {
      setRoleInfo(((await axios.get(`${process.env.REACT_APP_ENV === 'DEV' ? 'https://sysaccess-dev.cognitus.com' : ''}/roleInfo?grc=${grcMode === 'grc'}&env=${process.env.REACT_APP_ENV === 'DEV' ? 'DEVELOPMENT' : 'PRODUCTION'}`, { headers: { Authorization: 'Bearer ' + userToken?.token }})).data as Array<RoleInfo>))
    }
    setEmails((await axios.get(`${process.env.REACT_APP_ENV === 'DEV' ? 'https://sysaccess-dev.cognitus.com' : ''}/emails?env=${process.env.REACT_APP_ENV === 'DEV' ? 'DEVELOPMENT' : 'PRODUCTION'}`, { headers: { Authorization: 'Bearer ' + userToken?.token }})).data)
    setLoadingData(false);
  }

  useEffect(() => {
    if (graphData && graphData.data && graphData.data.userToken) {
      if (!showHistory) {
        fetchData(graphData.data.userToken)
      } else {
        if (grcMode === 'grc') {
          fetchGRCRequests(graphData.data.userToken)
        } else {
          fetchRequests(graphData.data.userToken)
        }
      }
    }
  }, [grcMode, showHistory])
  
  return (
    <div style={{width: "100%"}}>
      { Object.keys(alerts).filter(key => alerts[key] !== undefined).length > 0 ? (
        <>
          {Object.keys(alerts).filter(key => alerts[key] !== undefined).map((key, index) => {
            return(
              <TimedAlert 
                index={index}
                key={'alert-'+alerts[key]?.id} 
                remove={(id: string) => {
                  setAlerts(alerts => ({ ...alerts, [id]: undefined }))
                }} 
                id={alerts[key]?.id}  
                intent={alerts[key]?.intent}
                message={alerts[key]?.message}
                timeout={alerts[key]?.timeout}
              >
              </TimedAlert>
            )
          })}
        </>
      ) : null }
      {
        graphData.loading ? (<Spinner />) : graphData.error ? (<h1>{graphData.error.toString()}</h1>) : (
          <div style={{ display: 'grid', gridTemplateColumns: '100%', gap: '2em', alignContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', flexDirection: 'row' }}>
            { !loadingData ? <SystemsTable 
              data={data} 
              setGrcMode={setGrcMode}
              grcMode={grcMode} 
              editMode={false} 
              emails={emails} 
              sendAlert={sendAlert} 
              graphData={graphData} 
              fetchRequests={fetchRequests} 
              requests={requests}
              fetchGRCRequests={fetchGRCRequests} 
              grcRequests={grcRequests}
              requestedFor={requestedFor}
              setRequestedFor={setRequestedFor}
              showHistory={showHistory}
              setShowHistory={setShowHistory}
            /> : <Spinner/> }
          </div>
        ) 
      }
    </div>
  )
}
export default RequestTab;
